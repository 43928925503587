'use strict'

import _ from 'lodash'
import env_config from "../env";

export default {
    getArticles(cb, errorCb,request = '') {
        let content_id = _.get(request,'content_id')
        window.axios.get(_.get(env_config,'API_CMS_URL')+'api/v1/public/articles/'+content_id+'?includes=thumbnail,cover',
            {
                headers: {
                    'api-token': _.get(env_config,'API_CMS_TOKEN'),
                    'website-id' : _.get(env_config,'API_CMS_SITE_ID'),
                }
            }
            )
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })
    },
    getWinner(cb, errorCb) {
        window.axios.get(_.get(env_config,'API_CMS_URL')+'api/v1/public/articles?includes=thumbnail,cover&perPage=20&page=1&tags=การเดินทางของรางวัลที่1&sort=id&order=DESC',
            {
                headers: {
                    'api-token': _.get(env_config,'API_CMS_TOKEN'),
                    'website-id' : _.get(env_config,'API_CMS_SITE_ID'),
                }
            }
        )
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })
    },
}
