import pages from "./pages"
import articles from "./articles"
import galleries from "./galleries"
import contents from "./contents"
import posts from "./posts"
import products from "./products"

export default {
    pages,
    articles,
    galleries,
    contents,
    posts,
    products
}
