<template>
  <div class="px-2 lg:px-4 flex flex-wrap lg:flex-no-wrap w-full">
    <div class="p-4 px-2 lg:px-5 w-full lg:w-8/12">
      <div  class="w-full">
        <img  src="/images/pu-chok-dee.jpg" alt="" class="max-w-full h-auto align-middle object-scale-down">
      </div>
      <div v-if="contents" class="font-sans p-1 pr-2 pl-2">
        <div v-for="content in contents" v-bind:key="content.id">
          <div class="text-center bg-light-gray px-5 py-4 rounded-lg my-4">
            <h2 v-html="content.title" class="text-lg md:text-2xl text-primary font-bold f_kanit" style="color: rgba(76,163,173,var(--text-opacity)) !important;">
          </h2>
          </div>
          <img :src="content.thumbnail.url" class="w-full m-auto">
          <div v-html="content.body" class="font-sans w-full text-gray-800 text-justify article-body"></div>
          <div v-if="content.video_url" class="px-0 lg:px-16">
              <div v-html="content.video_url" class="winner-player text-center"></div>
          </div>
        </div>
      </div>
      <div v-else-if="is_404" class="text-center">
        <img src="/images/icon-404.png" alt="" class="mt-5 m-auto" width="80">
        <p class="pt-3 f_kanit">ไม่พบรายการ</p>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'
import api from "../api";

export default {
  data() {
    return {
      content_id: "",
      contents: "",
      is_404: false,
    };
  },
  mounted() {
    this.content_id = this.$route.params.content_id
    this.loadData()
  },
  methods: {
    loadData() {
      let vm = this
      api.articles.getWinner((response) => {
        vm.contents = _.get(response, 'data.data')
      }, () => {
        vm.is_404 = true
      }, {
        content_id: vm.content_id
      })

    }
  }
}
</script>
