<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
const default_layout = "blank"

export default {
  name: 'App',
  components: {
    //
  },
  computed: {
    layout() {
      return 'layout-'+(this.$route.meta.layout || default_layout)
    },
  },
}
</script>

<style>
#app {

}
</style>
