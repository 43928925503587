'use strict'

import _ from 'lodash'
import env_config from "../env";

export default {
    getPostShare(cb, errorCb,request = {}) {
        const post_id = _.get(request,'post_id')
        window.axios.get(_.get(env_config,'API_URL')+'posts/'+post_id+'/share')
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })
    },
}
