'use strict'

import _ from 'lodash'
import env_config from "../env";

export default {
    getGalleries(cb, errorCb,request = '') {
        let content_id = _.get(request,'content_id')
        window.axios.get(_.get(env_config,'API_CMS_URL')+'api/v1/public/galleries/'+content_id+'?includes=cover,photos',
            {
                headers: {
                    'api-token': _.get(env_config,'API_CMS_TOKEN'),
                    'website-id' : _.get(env_config,'API_CMS_SITE_ID'),
                }
            }
        )
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })
    },
}
