'use strict'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store  = new Vuex.Store({
    modules: {

    },
    state: {
        alert: '',
        step_register: 0,
        document_preview: null,
        isProgress: false,
        store_time: null,
    },
    mutations: {

    },
    actions: {

    },
    getters: {
        isProgress:(state) => {
            return state.isProgress
        },
    }
});

export default store;
