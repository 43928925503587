let env = process.env.ENV || 'alpha';

const domain = window.location.hostname
const env_domain = {
    "localhost":"local",
    "page.bluedragonlottery.cloud":"alpha",
    "page.bluedragonlottery.com":"production"
}
if(env_domain[domain]){
    env = env_domain[domain]
}else{
    console.log('error not domain map');
}

const env_config = {
    local: {
        ENV: 'local',
        API_URL: 'http://localhost:1111/',
        APP_URI: 'alphabdgapp',
        API_CMS_URL: 'https://cms-api.100stars.dev/',
        APP_PAGE_URL: 'https://page.bluedragonlottery.cloud/',
        API_CMS_TOKEN: 'dMYVoz9jiG/Y+Pif/wnbhKLV6yiAsxMIZmXtST2XWVnZNyEDhJfeCn7DM1iHb68bNg2gi7tjOfO3zo8s2/cYWkeBJapTfdWv',
        API_CMS_SITE_ID: 11,
        RESIZE_URL: 'https://alpha-res.bluedragonlottery.cloud/'
    },
    alpha: {
        ENV: 'alpha',
        API_URL: 'https://alpha-api.bluedragonlottery.cloud/',
        APP_URI: 'alphabdgapp',
        API_CMS_URL: 'https://cms-api.100stars.dev/',
        APP_PAGE_URL: 'https://page.bluedragonlottery.cloud/',
        API_CMS_TOKEN: 'dMYVoz9jiG/Y+Pif/wnbhKLV6yiAsxMIZmXtST2XWVnZNyEDhJfeCn7DM1iHb68bNg2gi7tjOfO3zo8s2/cYWkeBJapTfdWv',
        API_CMS_SITE_ID: 11,
        RESIZE_URL: 'https://alpha-res.bluedragonlottery.cloud/'
    },
    production: {
        ENV: 'production',
        API_URL: 'https://api.bluedragonlottery.cloud/',
        APP_URI: 'bdgapp',
        API_CMS_URL: 'https://cms-api.100stars.dev/',
        APP_PAGE_URL: 'https://page.bluedragonlottery.com/',
        API_CMS_TOKEN: 'dMYVoz9jiG/Y+Pif/wnbhKLV6yiAsxMIZmXtST2XWVnZNyEDhJfeCn7DM1iHb68bNg2gi7tjOfO3zo8s2/cYWkeBJapTfdWv',
        API_CMS_SITE_ID: 11,
        RESIZE_URL: 'https://res.bluedragonlottery.cloud/'
    }
}[env];

export default env_config;
