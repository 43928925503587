'use strict'

import _ from 'lodash'
import env_config from "../env";

export default {
    getContent(cb, errorCb,request = '') {
        let content_id = _.get(request,'content_id')
        window.axios.get(_.get(env_config,'API_URL')+'contents/'+content_id+'?fields=id,images(id,tag,name,position),fields(label,name,lang),published_at',
            {
                headers: {
                    'Accept-Language': 'th'
                }
            }
        )
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })
    }
}
