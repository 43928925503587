import VueRouter from 'vue-router'


let routes = [
    {
        path: '/',
        name: 'home',
        component: require('./pages/index').default,
    },
    {
        path: '/f/:slug',
        name: 'follow',
        component: require('./pages/index').default,
    },
    {
        path: '/f/d/:slug',
        name: 'follow',
        meta : {is_user_ref: true},
        component: require('./pages/index').default,
    },
    {
        path: '/p/:post_id', //s share
        name: 'posts',
        component: require('./pages/post').default,
    },
    {
        path: '/h/:product_id', //s share
        name: 'horoscope',
        component: require('./pages/horoscope').default,
    },
    {
        path: '/m/:slug',
        name: 'menu',
        component: require('./pages/menu').default,
    },
    {
        path: '/articles/:content_id',
        name: 'articles',
        component: require('./pages/articles').default,

    },
    {
        path: '/galleries/:content_id',
        name: 'galleries',
        component: require('./pages/galleries').default,

    },
    {
        path: '/winner',
        name: 'winner',
        component: require('./pages/winner').default,
    },
    {
        path: '/contents/:content_id',
        name: 'contents',
        component: require('./pages/content').default,
    },
    {
        path: '/editor',
        name: 'editor',
        component: require('./pages/editor').default,
    }
];

const router = new VueRouter({
    routes,
    // linkActiveClass: 'active',
    mode: 'history'
});


// middleware
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Than run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
        const context    = { from, next, router, to, }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({ ...context, next: nextMiddleware })
    }

    return next()
});

// router.afterEach((to, from) => {
//   //  finish the progress bar
//   store.dispatch('setProgress', false)
// })

export default router;
