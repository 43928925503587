import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import moment from 'moment'
import router from './routes'
import Blank from './layouts/Blank'
import App from './App.vue'
import VueMobileDetection from 'vue-mobile-detection'
import VueSweetalert2 from 'vue-sweetalert2'


import 'sweetalert2/dist/sweetalert2.min.css'

require('./bootstrap');

Vue.config.productionTip = false

window.axios = require('axios');


Vue.use(VueRouter)
Vue.use(VueMobileDetection)
Vue.use(VueSweetalert2)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});

Vue.component('layout-blank',Blank)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
