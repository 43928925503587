<template>
  <div class="px-2 lg:px-4 flex flex-wrap lg:flex-no-wrap w-full">
    <div class="p-4 px-2 lg:px-5 w-full lg:w-8/12">
      <div v-if="body" class="font-sans p-1 pr-2 pl-2" >
        <h1 class="mt-2 font-sans text-2xl font-semibold text-primary pb-3" v-html="title" style="color: rgba(76,163,173,var(--text-opacity)) !important;">

        </h1>
        <div class="flex justify-between items-center">
          <span class="text-gray-800">{{updated_at | formatDateTime }} น.</span>
        </div>
        <div v-show="show_cover" class="w-full pt-4 pb-10 flex items-center justify-center">
          <img :src="cover" alt="" class="object-scale-down">
        </div>
        <div class="w-full text-gray-800 text-justify article-body" v-html="body">
        </div>
      </div>
      <div v-else-if="is_404" class="text-center">
        <img src="/images/icon-404.png" alt="" class="mt-5 m-auto" width="80">
        <p class="pt-3 f_kanit">ไม่พบรายการ</p>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'
import api from "../api";
//import env_config from "../env";

export default {
  data() {
    return {
      content_id: "",
      title: "",
      body: "",
      cover: "",
      updated_at: "",
      is_404: false,
      show_cover: false
    };
  },
  mounted() {
    this.content_id = this.$route.params.content_id
    //this.show_cover = _.get(env_config,'ENV') === 'production' ? true : false
    this.loadData()
  },
  methods: {
    loadData() {
      let vm = this
      api.articles.getArticles((response) => {
        vm.title = _.get(response, 'data.data.title')
        vm.body = _.get(response, 'data.data.body')
        vm.cover = _.get(response, 'data.data.cover.url')
        vm.updated_at =  _.get(response, 'data.data.updated_at')
      }, () => {
        vm.is_404 = true
      }, {
        content_id: vm.content_id
      })

    }
  }
}
</script>

<style scoped>
  a {
   line-break:anywhere;
 }
</style>
