<template>
  <div class="px-2 lg:px-4 flex flex-wrap lg:flex-no-wrap w-full">
    <div class="p-4 px-2 lg:px-5 w-full lg:w-8/12">
      <div v-if="photos" class="font-sans p-1 pr-2 pl-2">
        <h1 class="mt-4 font-sans text-2xl font-semibold text-primary" v-html="title" style="color: rgba(76,163,173,var(--text-opacity)) !important;">
        </h1>
        <div class="pt-4 pb-2">
          <div class="flex justify-between items-center">
            <span class="text-gray-800">{{updated_at | formatDateTime }}</span>
          </div>
        </div>
        <div class="w-full pt-4 pb-10 flex flex-wrap">
          <a :href="photo.url" class="w-full md:w-1/4 p-1" v-for="photo in photos" v-bind:key="photo.id" data-gallery>
            <img :src="photo.url" :alt="photo.name">
          </a>
        </div>
        <div class="w-full text-gray-800 text-justify article-body"><div class="mb-3">
          ✅ <strong>ซื้อล็อตเตอรี่</strong>กับ<strong>มังกรฟ้า</strong>วันนี้
          นอกจากท่านจะได้สนุกลุ้นโชคแล้ว
          ยังได้เป็นผู้ให้และได้ตอบแทนสังคมอย่างแท้จริง 😊
        </div> <div class="mb-3">
          🌈
          <strong>บริษัท มังกรฟ้า</strong>
          เราประกอบกิจการด้วยความซื่อสัตย์สุจริตเป็นที่มั่น
          และเราต้องการเป็นตัวแทนของลูกค้าทุกท่าน ในการแบ่งปันและช่วยเหลือสังคม
          ในทุกๆ ยอดซื้อฝากร้าน
          ทาง<strong>มังกรฟ้า</strong>จะนำรายได้จากสลากทุกใบที่ท่านซื้อนำไปสร้างโอกาส
          สร้างชีวิต สร้างความสุข ให้แก่เด็กกำพร้า เด็กด้อยโอกาส ผู้พิการ
          เพื่อให้เกิดประโยชน์สูงสุดต่อไป 🧒👧
        </div> <div>
          💝 <strong>มังกรฟ้า ยิ่งซื้อ ยิ่งให้</strong> 💝
          ขอให้ทุกท่านที่มีส่วนร่วมแบ่งปันไปกับเรามีแต่ความสุข ความเจริญ ยิ่งๆ
          ขึ้นไปครับ
        </div></div>
      </div>
      <div v-else-if="is_404" class="text-center">
        <img src="/images/icon-404.png" alt="" class="mt-5 m-auto" width="80">
        <p class="pt-3 f_kanit">ไม่พบรายการ</p>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'
import api from "../api";

export default {
  data() {
    return {
      content_id: "",
      photos: [],
      updated_at: "",
      title: "",
      is_404: false,
    };
  },
  mounted() {
    this.content_id = this.$route.params.content_id
    this.loadData()
  },
  methods: {
    loadData() {
      let vm = this
      api.galleries.getGalleries((response) => {
        vm.title = _.get(response, 'data.data.name')
        vm.photos = _.get(response, 'data.data.photos')
        vm.updated_at =  _.get(response, 'data.data.updated_at')
      }, () => {
        vm.is_404 = true
      }, {
        content_id: vm.content_id
      })

    }
  }
}
</script>
