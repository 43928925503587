<template>
  <div>
    <div v-if="$isMobile()">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center pt-5">
            <!--<img src="/images/LOGO2.png" width="220" alt="" class="m-auto">-->
            <img src="/images/logo3.png" width="220" alt="" class="m-auto">

            <h6 style="font-weight: bold" class="mt-3 mb-5 f_kanit">โปรดเปิด แอปมังกรฟ้า<br/>แล้วดำเนินการต่อ</h6>
            <a href="#" @click="linkToProd()" class="btn btn-primary f_kanit" style="width: 300px; background-color: #4da4ad; color: #fff; border-color: #17a2b8;padding:.5em;">เปิด แอปมังกรฟ้า</a><br/>
            <!--<a href="#" @click="linkToProd()" class="btn btn-default mt-3 f_kanit" style="width: 300px; border-color: #e0e0e0;padding:.5em;">ดาวน์โหลด แอปมังกรฟ้า</a>-->
            <!--<a v-if="uri == 'alphabdgapp'" @click="linkBata()" class="btn btn-default mt-3 f_kanit" style="width: 300px; border-color: #e0e0e0;padding:.5em;">เปิด แอปมังกรฟ้า เบต้า</a>-->
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <header class="p-3" style="border-bottom: 1px solid #ccc">
        <div class="container">
          <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a href="" class="d-flex align-items-center mb-lg-0 text-white text-decoration-none" target="_blank">
              <img src="/images/header-logo-lg-2.png" alt="" height="80" style="height: 80px">
            </a>
          </div>
        </div>
      </header>
      <div class="container">
        <div class="row">
          <div v-if="is_loading" class="col-12 text-center" style="padding-top: 100px">
            <img src="/images/Loading_2.gif" alt="" width="80" class="m-auto">
          </div>
          <div v-else class="col-12 text-center pt-5">
            <div v-if="is_404">
              <img src="/images/icon-404.png" alt="" class="mt-5  m-auto" width="80">
              <p class="pt-3 f_kanit">ไม่พบรายการ</p>
            </div>
            <div v-else>
              <div  class="pt-5">
                <vue-qrcode :value="url" :width="250"  style="border: 1px solid #999" class=" m-auto"/>
              </div>
              <br/>
              <h6 style="font-weight: bold; font-size:1.2em" class="mt-3 f_kanit" v-text="page_name"></h6>
              <p style="font-weight:400" class="f_kanit">
                เปิดแอปมังกรฟ้าเลือกไอคอนสแกนที่แทบด้านขวาบน<br/>
                และสแกนคิวอาร์โค้ดนี้ เพื่อติดตามเพจได้เลย
              </p>
              <p>
                <!--<a :href="onelink" class="f_kanit" style="font-weight: bold; color: #4da4ad">ดาวน์โหลดแอปมังกรฟ้า</a>-->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import _ from "lodash"
import VueQrcode from 'vue-qrcode'
import api from "../api"
import env_config from "../env";

export default {
  components: {
    VueQrcode
  },
  data() {
    return {
      is_user_ref: false,
      is_404: false,
      is_loading: true,
      page_name: '',
      user_ref: '',
      slug: '',
      url: '',
      app_uri: '',
      uri: '',
      onelink: 'http://onelink.to/zhyaxd'
    };
  },
  mounted(){
    this.slug =   this.$route.params.slug
    this.is_user_ref = _.get(this.$route.meta,'is_user_ref')

    if(!this.is_user_ref){
      this.app_uri = _.get(env_config,'APP_URI')+'://page/f/'+this.slug
      this.uri = _.get(env_config,'APP_URI')
      this.url  =   window.location.href //"https://page.bluedragonlottery.cloud/f/"+this.slug
    }
    if(this.slug){
      //console.log(this.slug)
      this.loadData()
    }else{
      this.is_loading = false
      this.is_404 = true

    }
  },
  methods: {
    linkBata(){
      window.open("https://vbqe2.app.link/x78PPvj1Ngb?$deeplink_path="+this.url+'&openExternalBrowser=1')
    },
    linkToProd(){
      window.open("https://vbqe2.app.link/x78PPvj1Ngb?$deeplink_path="+this.url+'&openExternalBrowser=1')
    },
    loadData(){
      let request = "?slug="
      if(this.is_user_ref){
        request = "?user_ref="+this.slug
      }else{
        request = "?slug="+this.slug
      }

      api.pages.getCheckPageExists(
          (response) => {
            if(!_.isEmpty(_.get(response.data.data,'page'))){
              this.page_name = _.get(response.data.data,'page.display_name')
              if(this.is_user_ref){
                let basic_id = _.get(response.data.data,'page.basic_id')
                this.url  =  _.get(env_config,'APP_PAGE_URL')+'f/'+basic_id
                this.app_uri = _.get(env_config,'APP_URI')+'://page/f/'+basic_id
              }
            }else{
              this.is_404 = true
            }
            this.is_loading = false
          },
          (error) => {
            if (error.response) {
              //
            }
          },request)
    },
    openUrl(){
      window.location.href = this.url
    },
    linkTo(){
      let vm = this
      document.location = vm.app_uri;
      setTimeout(function(){
        if(confirm('คุณยังไม่ได้ติดตั้งแอปมังกรฟ้า, ต้องการดาวน์โหลดใช่หรือไม่?')){
          //document.location = vm.onelink
          window.location.href = vm.onelink
        }
      }, 300);
    }
  },
  watch: {
    //
  }
}
</script>
