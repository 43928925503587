'use strict'

import _ from 'lodash'
import env_config from "../env";

export default {
    getProductShare(cb, errorCb,request = {}) {
        const product_id = _.get(request,'product_id')
        window.axios.get(_.get(env_config,'API_URL')+'products/'+product_id+'/share')
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })
    },
}
