'use strict'

import _ from 'lodash'
import env_config from "../env";

export default {
    getCheckPageExists(cb, errorCb,request = '') {
        window.axios.get(_.get(env_config,'API_URL')+'check-page-exists'+request)
            .then(response => cb(response))
            .catch(err => {
                if (typeof errorCb === 'function') {
                    errorCb(err.response)
                }
            })
    },
}
