<template>
  <div class="px-2 lg:px-4 flex flex-wrap lg:flex-no-wrap w-full">
    <div class="row">
      <div class="col-12">
        <div class="p-4 px-2 lg:px-5 w-full lg:w-8/12">
          <div v-if="body" class="font-sans p-1 pr-2 pl-2" >
            <h2 class="mt-2 font-sans font-semibold text-primary pb-3" v-html="title" style="color: rgba(76,163,173,var(--text-opacity)) !important; font-size: 1rem;"></h2>
            <div class="flex justify-between items-center">
              <span class="text-gray-800">{{updated_at | formatDate }}</span>
            </div>
            <div v-show="show_cover" class="w-full pt-4  flex items-center justify-center">
              <img :src="cover" alt="" class="img-fluid"  >
            </div>
            <div class="w-full text-gray-800 text-justify article-body" v-html="body">
            </div>
          </div>
          <div v-else-if="is_404" class="text-center">
            <img src="/images/icon-404.png" alt="" class="mt-5 m-auto" width="80">
            <p class="pt-3 f_kanit">ไม่พบรายการ</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'
import api from "../api";
import env_config from "../env";

export default {
  data() {
    return {
      content_id: "",
      title: "",
      body: "",
      cover: "",
      updated_at: "",
      is_404: false,
      show_cover: false
    };
  },
  created() {
    //
  },
  mounted() {
   // this.show_cover = _.get(env_config,'ENV') === 'production' ? true : false
    this.content_id = this.$route.params.content_id
    this.loadData()
  },
  methods: {
    loadData() {
      let vm = this
      api.contents.getContent((response) => {
        let fields = _.get(response, 'data.data.fields')
        let field_tile = _.find(fields,{name: 'title'})
        let field_description = _.find(fields,{name: 'description'})
        let images = _.get(response, 'data.data.images')
        let coverUrl = null
        if(!_.isEmpty(images)){
          let image = _.first(images)
          if(!_.isEmpty(image)){
            coverUrl = _.get(env_config,'RESIZE_URL')+'/c_thumb/'+_.get(image,'id')+'/'+_.get(image,'name')
          }
        }

        vm.title = _.get(field_tile,'lang')
        vm.body = _.get(field_description,'lang')
        vm.cover = coverUrl
        vm.updated_at =  _.get(response, 'data.data.published_at')
      }, () => {
        vm.is_404 = true
      }, {
        content_id: vm.content_id
      })

    }
  }
}
</script>
